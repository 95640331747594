import React, { Component } from 'react';
import { AppRegistry, View, Image, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    paddingTop: 50,
    justifyContent: 'center',
                alignItems: 'center',
  },
  tinyLogo: {
    width: 300,
    height: 300,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 1000,
    height: 1000,
  },
});

class DisplayAnImage extends Component {
  render() {
    return (
      <View style={styles.container}>
        <Image
          style={styles.tinyLogo}
          source={require('./project-lakehouse-expo/300x300.png')}
        />
      </View>
    );
  }
}

export default DisplayAnImage;
